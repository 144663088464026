var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(!_vm.companiesLoading && _vm.checkAuthUser())?_c('div',{staticClass:"col-md-12"},[_c('validation-observer',{ref:"configurationValidation"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('b-card',{staticClass:"card-form-config-companies"},[_c('div',{staticClass:"card-header bg-white"},[_c('h4',{staticClass:"card-title"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"1.5x"}}),_vm._v("Detalle ")],1)]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"company-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-name","name":"company-name"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2833825542)})],1),_c('b-form-group',{attrs:{"label-for":"company-fantasy-name"}},[_c('validation-provider',{attrs:{"name":"Nombre fantasía"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('label',[_vm._v(" Nombre fantasía "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Si quiere colocar un nombre personalizado de su\n                          negocio\n                          diferente al registrado en hacienda y este salga en\n                          sus\n                          facturas llene este campo sino déjelo vacío.","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('b-form-input',{attrs:{"id":"company-fantasy-name","name":"company-fantasy-name"},model:{value:(_vm.company.fantasy_name),callback:function ($$v) {_vm.$set(_vm.company, "fantasy_name", $$v)},expression:"company.fantasy_name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2147780889)})],1),_c('b-form-group',{attrs:{"label":"Tipo identificación","label-for":"company-identification-type"}},[_c('validation-provider',{attrs:{"name":"Tipo identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"company-identification-type","options":_vm.identificationTypes},model:{value:(_vm.company.identification_type),callback:function ($$v) {_vm.$set(_vm.company, "identification_type", $$v)},expression:"company.identification_type"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2934340689)})],1),_c('b-form-group',{attrs:{"label":"Identificación","label-for":"company-identification"}},[_c('validation-provider',{attrs:{"name":"Identificación","rules":_vm.validateIdentificationLength(_vm.company.identification_type)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-identification","name":"company-identification"},model:{value:(_vm.company.identification),callback:function ($$v) {_vm.$set(_vm.company, "identification", $$v)},expression:"company.identification"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3417980747)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"company-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-email","name":"company-email"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,776340429)})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"company-phone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-phone","name":"company-phone"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1728537565)})],1),_c('b-form-group',{attrs:{"label-for":"company-logo"}},[_c('validation-provider',{attrs:{"name":"Logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"mr-1"},[_vm._v("Logo")]),_c('small',{staticClass:"text-primary"},[_vm._v("Para cambiar el logo elige uno nuevo.")])]),(!_vm.company.logo_file)?_c('b-img',{staticStyle:{"width":"100px","height":"100px","display":"block","object-fit":"contain"},attrs:{"src":(_vm.url_logo + "/" + (_vm.company.logo))},on:{"error":_vm.handleImgError}}):_vm._e(),(_vm.company.logo_file)?_c('b-img',{staticStyle:{"width":"100px","height":"100px","display":"block","object-fit":"contain"},attrs:{"src":("" + (_vm.company.logo))},on:{"error":_vm.handleImgError}}):_vm._e(),_c('b-form-file',{staticClass:"custom-file-input",attrs:{"id":"custom-file-input","plain":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('label',{staticClass:"custom-file-input-label",attrs:{"for":"custom-file-input"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Subir logo")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3367854282)})],1)],1)])],1),_c('div',{staticClass:"col-md-4"},[_c('b-card',{staticClass:"card-form-config-companies"},[_c('div',{staticClass:"card-header bg-white"},[_c('h4',{staticClass:"card-title"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"1.5x"}}),_vm._v("Ubicación ")],1)]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label":"Provincia","label-for":"company-province"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company-province","options":_vm.provinces,"reduce":function (province) { return province.id; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeProvince($event)}},model:{value:(_vm.company.province),callback:function ($$v) {_vm.$set(_vm.company, "province", $$v)},expression:"company.province"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron provincias ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] ),expression:"errors[0] "}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,777091627)})],1),_c('b-form-group',{attrs:{"label":"Cantón","label-for":"company-canton"}},[_c('validation-provider',{attrs:{"name":"Cantón","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company-canton","options":_vm.cantons,"reduce":function (canton) { return canton.number; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeCanton($event)}},model:{value:(_vm.company.canton),callback:function ($$v) {_vm.$set(_vm.company, "canton", $$v)},expression:"company.canton"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron cantones ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] ),expression:"errors[0] "}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2422262030)})],1),_c('b-form-group',{attrs:{"label":"Distrito","label-for":"company-district"}},[_c('validation-provider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company-district","options":_vm.districts,"reduce":function (district) { return district.number; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeDistrict($event)}},model:{value:(_vm.company.district),callback:function ($$v) {_vm.$set(_vm.company, "district", $$v)},expression:"company.district"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron distritos ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] ),expression:"errors[0] "}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,936201464)})],1),_c('b-form-group',{attrs:{"label":"Barrio","label-for":"company-barrio"}},[_c('validation-provider',{attrs:{"name":"Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company-barrio","options":_vm.barrios,"reduce":function (barrio) { return barrio.id; },"label":"name","searchable":""},model:{value:(_vm.company.barrio),callback:function ($$v) {_vm.$set(_vm.company, "barrio", $$v)},expression:"company.barrio"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron barrios ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2501447464)})],1),_c('b-form-group',{attrs:{"label":"Otras Señas","label-for":"company-other-signs"}},[_c('validation-provider',{attrs:{"name":"Otras Señas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-other-signs","name":"company-other-signs"},model:{value:(_vm.company.other_signs),callback:function ($$v) {_vm.$set(_vm.company, "other_signs", $$v)},expression:"company.other_signs"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2997320090)})],1),_c('b-form-group',{attrs:{"label":"Actividad Económica","label-for":"company-economic_activity"}},[_c('validation-provider',{attrs:{"name":"Actividad Económica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-economic_activity","options":_vm.economic_activities,"reduce":function (economic_activity) { return economic_activity.id; },"label":"name","searchable":""},model:{value:(_vm.company.economic_activity),callback:function ($$v) {_vm.$set(_vm.company, "economic_activity", $$v)},expression:"company.economic_activity"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron actividades económicas ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3449725229)})],1)],1)])],1),_c('div',{staticClass:"col-md-4"},[_c('b-card',{staticClass:"card-form-config-companies"},[_c('div',{staticClass:"card-header bg-white"},[_c('h4',{staticClass:"card-title"},[_c('feather-icon',{attrs:{"icon":"SettingsIcon","size":"1.5x"}}),_vm._v("Preferencias ")],1)]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label-for":"other_configs-custom_quotation_name"}},[_c('validation-provider',{attrs:{"name":"Nombre Cotización(preforma...)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('label',[_vm._v(" Nombre Cotización(preforma...) "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"El nombre que aparecerá en todo el sistema y documentos para tus cotizaciones","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('b-form-input',{attrs:{"id":"other_configs-custom_quotation_name","name":"other_configs-custom_quotation_name"},model:{value:(_vm.other_configs.custom_quotation_name),callback:function ($$v) {_vm.$set(_vm.other_configs, "custom_quotation_name", $$v)},expression:"other_configs.custom_quotation_name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,730202392)})],1),_c('b-form-group',{attrs:{"label-for":"other_configs-currency"}},[_c('validation-provider',{attrs:{"name":"Moneda sistema","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('label',[_vm._v(" Moneda sistema "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Es la moneda que mostrará el sistema en el dashboard y en los reportes","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('b-form-select',{attrs:{"id":"other_configs-currency","options":_vm.currencies,"value-field":"id","text-field":"name"},model:{value:(_vm.other_configs.currency),callback:function ($$v) {_vm.$set(_vm.other_configs, "currency", $$v)},expression:"other_configs.currency"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3390563596)})],1),_c('b-form-group',{attrs:{"label-for":"other_configs-custom_quotation_name"}},[_c('validation-provider',{attrs:{"name":"Modo POS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"m-0"},[_vm._v(" Modo POS "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Cuando el modo POS está activo habilita un buscador\n                          de productos al momento de generar documentos que agiliza el\n                          proceso pensado en puntos de venta","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('div',{staticClass:"ml-2 d-flex align-items-center"},[_c('h6',{staticClass:"mr-1 m-0 font-weight-bolder",class:!_vm.other_configs.mode_pos ? 'text-primary' : ''},[_vm._v("NO")]),_c('b-form-checkbox',{attrs:{"id":"other_configs-mode_pos","name":"other_configs-mode_pos","switch":""},model:{value:(_vm.other_configs.mode_pos),callback:function ($$v) {_vm.$set(_vm.other_configs, "mode_pos", $$v)},expression:"other_configs.mode_pos"}}),_c('h6',{staticClass:"ml-1 m-0 font-weight-bolder",class:_vm.other_configs.mode_pos ? 'text-primary' : ''},[_vm._v("SI")])],1)]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1456785574)})],1),_c('b-form-group',{attrs:{"label-for":"other_configs-currency_emit_doc"}},[_c('validation-provider',{attrs:{"name":"Moneda por defecto al emitir documentos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('label',[_vm._v(" Moneda por defecto al emitir documentos "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Con esta opción puedes elegir una moneda por defecto que aparezca preseleccionada al emitir documentos\n                          o dejalo en preguntar para que siempre te\n                          pregunte el tipo de moneda.","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('b-form-select',{attrs:{"id":"other_configs-currency_emit_doc","options":_vm.currencies_emit_docs,"value-field":"id","text-field":"name"},model:{value:(_vm.other_configs.currency_emit_doc),callback:function ($$v) {_vm.$set(_vm.other_configs, "currency_emit_doc", $$v)},expression:"other_configs.currency_emit_doc"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,350958599)})],1),(_vm.user.user_role.name === 'admin' || _vm.user.user_role.name === 'superadmin')?_c('b-form-group',{attrs:{"label-for":"other_configs-plan_id"}},[_c('validation-provider',{attrs:{"name":"Plan","rules":_vm.user.user_role.name === 'admin' || _vm.user.user_role.name === 'superadmin' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('label',[_vm._v(" Plan "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Plan por el momento puede elegir solo recepción,\n                          full facturación o Escuela.","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('b-form-select',{attrs:{"id":"company-plan_id","options":_vm.plans,"value-field":"id","text-field":"name"},model:{value:(_vm.company.plan_id),callback:function ($$v) {_vm.$set(_vm.company, "plan_id", $$v)},expression:"company.plan_id"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2879864065)})],1):_vm._e(),(_vm.user.user_role.name === 'admin' || _vm.user.user_role.name === 'superadmin')?_c('b-form-group',{attrs:{"label-for":"other_configs-stop_generate_xmls"}},[_c('validation-provider',{attrs:{"name":"Apagar generación de XMLS Hacienda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"m-0"},[_vm._v(" Apagar generación de XMLS Hacienda "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Si pones esta opción en SI se generaran facturas pero sin que se generen xml de las mismas\n                          y sin envió a hacienda","icon":"HelpCircleIcon","size":"1.5x"}})],1),_c('div',{staticClass:"ml-2 d-flex align-items-center"},[_c('h6',{staticClass:"mr-1 m-0 font-weight-bolder",class:!_vm.other_configs.stop_generate_xmls ? 'text-primary' : ''},[_vm._v("NO")]),_c('b-form-checkbox',{attrs:{"id":"other_configs-stop_generate_xmls","name":"other_configs-stop_generate_xmls","switch":""},model:{value:(_vm.other_configs.stop_generate_xmls),callback:function ($$v) {_vm.$set(_vm.other_configs, "stop_generate_xmls", $$v)},expression:"other_configs.stop_generate_xmls"}}),_c('h6',{staticClass:"ml-1 m-0 font-weight-bolder",class:_vm.other_configs.stop_generate_xmls ? 'text-primary' : ''},[_vm._v("SI")])],1)]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,345792873)})],1):_vm._e()],1)])],1)]),_c('div',{staticClass:"col-md-12 align-items-center justify-content-end d-flex text-right"},[_c('b-button',{attrs:{"type":"text","variant":"primary"},on:{"click":_vm.submit}},[(!_vm.submitLoading)?_c('span',[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Guardar")]):_vm._e(),(_vm.submitLoading)?_c('span',[_c('b-spinner',{attrs:{"small":"","variant":"light","label":"Spinning"}})],1):_vm._e()])],1)])],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[(_vm.companiesLoading || !_vm.checkAuthUser() )?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('b-spinner'),_c('strong',{staticClass:"ml-1"},[_vm._v("Cargando...")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }