var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(!_vm.loading)?_c('b-col',{attrs:{"md":"12","sm":"12"}},[_c('validation-observer',{ref:"haciendaValidation"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"card-form-config-companies"},[_c('div',{staticClass:"card-header bg-white"},[_c('h4',{staticClass:"card-title"},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon","size":"1.5x"}}),_vm._v("Consecutivos ")],1)]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label":"Consecutivo actual Facturas (FE)","label-for":"hacienda-cfe"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Facturas (FE)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cfe","name":"hacienda-cfe"},model:{value:(_vm.hacienda.cfe),callback:function ($$v) {_vm.$set(_vm.hacienda, "cfe", $$v)},expression:"hacienda.cfe"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3060049419)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Tiquetes (TE)","label-for":"hacienda-cte"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Tiquetes (TE)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cte","name":"hacienda-cte"},model:{value:(_vm.hacienda.cte),callback:function ($$v) {_vm.$set(_vm.hacienda, "cte", $$v)},expression:"hacienda.cte"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2813989529)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Notas de credio (NC)","label-for":"hacienda-cnc"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Notas de credio (NC)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cnc","name":"hacienda-cnc"},model:{value:(_vm.hacienda.cnc),callback:function ($$v) {_vm.$set(_vm.hacienda, "cnc", $$v)},expression:"hacienda.cnc"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2077352005)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Notas de debito (ND)","label-for":"hacienda-cnd"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Notas de debito (ND)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cnd","name":"hacienda-cnd"},model:{value:(_vm.hacienda.cnd),callback:function ($$v) {_vm.$set(_vm.hacienda, "cnd", $$v)},expression:"hacienda.cnd"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2057403490)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos aceptados (CCE)","label-for":"hacienda-ccce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos aceptados (CCE)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-ccce","name":"hacienda-ccce"},model:{value:(_vm.hacienda.ccce),callback:function ($$v) {_vm.$set(_vm.hacienda, "ccce", $$v)},expression:"hacienda.ccce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1444363917)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos aceptados parcialmente (CPCE)","label-for":"hacienda-cpce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos aceptados parcialmente (CPCE)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cpce","name":"hacienda-cpce"},model:{value:(_vm.hacienda.cpce),callback:function ($$v) {_vm.$set(_vm.hacienda, "cpce", $$v)},expression:"hacienda.cpce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2925097502)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos rechazados (RCE)","label-for":"hacienda-crce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos rechazados (RCE)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-crce","name":"hacienda-crce"},model:{value:(_vm.hacienda.crce),callback:function ($$v) {_vm.$set(_vm.hacienda, "crce", $$v)},expression:"hacienda.crce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2512034076)})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual facturas de compra (Simplificado FEC)","label-for":"hacienda-fec"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual facturas de compra (Simplificado FEC)","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-fec","name":"hacienda-fec"},model:{value:(_vm.hacienda.fec),callback:function ($$v) {_vm.$set(_vm.hacienda, "fec", $$v)},expression:"hacienda.fec"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,845940971)})],1)],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"card-form-config-companies"},[_c('div',{staticClass:"card-header bg-white"},[_c('h4',{staticClass:"card-title"},[_c('feather-icon',{attrs:{"icon":"KeyIcon","size":"1.5x"}}),_vm._v("Datos de acceso ")],1)]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label":"Usuario","label-for":"hacienda-username"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-username","name":"hacienda-username"},model:{value:(_vm.hacienda.username),callback:function ($$v) {_vm.$set(_vm.hacienda, "username", $$v)},expression:"hacienda.username"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3696088765)})],1),_c('b-form-group',{attrs:{"label":"Clave","label-for":"hacienda-password"}},[_c('validation-provider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-password","name":"hacienda-password","type":"password"},model:{value:(_vm.hacienda.password),callback:function ($$v) {_vm.$set(_vm.hacienda, "password", $$v)},expression:"hacienda.password"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,925364261)})],1),_c('b-form-group',{attrs:{"label":"PIN llave criptografica","label-for":"hacienda-pin"}},[_c('validation-provider',{attrs:{"name":"PIN llave criptografica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-pin","name":"hacienda-pin","type":"password"},model:{value:(_vm.hacienda.pin),callback:function ($$v) {_vm.$set(_vm.hacienda, "pin", $$v)},expression:"hacienda.pin"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2183618413)})],1),_c('b-form-group',{attrs:{"label":"Archivo llave criptografica","label-for":"hacienda-key"}},[_c('validation-provider',{attrs:{"name":"Archivo llave criptografica"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-file',{ref:"hacienda-key",attrs:{"name":"hacienda-key","browse-text":"Examinar","rules":_vm.hacienda.id ? '' : 'required'},on:{"change":function($event){return _vm.onChangeFile($event)}},model:{value:(_vm.hacienda.key),callback:function ($$v) {_vm.$set(_vm.hacienda, "key", $$v)},expression:"hacienda.key"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2260929670)})],1),_c('b-form-group',{attrs:{"label-for":"hacienda-type"}},[_c('validation-provider',{attrs:{"name":"Modo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"m-0"},[_vm._v("Modo")]),_c('div',{staticClass:"ml-2 d-flex align-items-center"},[_c('h6',{staticClass:"mr-1 m-0 font-weight-bolder",class:!_vm.hacienda.type ? 'text-primary' : ''},[_vm._v("En producción")]),_c('b-form-checkbox',{attrs:{"id":"hacienda-type","name":"hacienda-type","switch":""},model:{value:(_vm.hacienda.type),callback:function ($$v) {_vm.$set(_vm.hacienda, "type", $$v)},expression:"hacienda.type"}}),_c('h6',{staticClass:"ml-1 m-0 font-weight-bolder",class:_vm.hacienda.type ? 'text-primary' : ''},[_vm._v("En pruebas")])],1)]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3459042030)})],1)],1)])],1),_c('b-col',{staticClass:"align-items-center justify-content-end d-flex text-right",attrs:{"md":"12"}},[_c('b-button',{attrs:{"type":"text","variant":"primary"},on:{"click":_vm.submit}},[(!_vm.submitLoading)?_c('span',[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Guardar")]):_vm._e(),(_vm.submitLoading)?_c('span',[_c('b-spinner',{attrs:{"small":"","variant":"light","label":"Spinning"}})],1):_vm._e()])],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[(_vm.loading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('b-spinner'),_c('strong',{staticClass:"ml-1"},[_vm._v("Cargando...")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }